import {Add, Edit, KeyboardArrowDown} from "@mui/icons-material";
import {Box, Button, Card, Grid, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {H6} from "../../../../components/typography";
import FlexBox from "../../../../components/flexbox/FlexBox";
import {IconWrapper} from "../../../../components/icon-wrapper"; // CUSTOM ICON COMPONENT
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import BackendService from "../../../../utils/backendService";


const CAMPAIGN_STATUS_OPTIONS = [
    {id: 'ACTIVE', value: 'ACTIVE'},
    {id: 'PAUSED', value: 'PAUSED'}
]

const CAMPAIGN_OBJECTIVE_OPTIONS = [
    {id: 'OUTCOME_AWARENESS', value: 'OUTCOME_AWARENESS'},
    {id: 'OUTCOME_ENGAGEMENT', value: 'OUTCOME_ENGAGEMENT'},
    {id: 'OUTCOME_LEADS', value: 'OUTCOME_LEADS'},
    {id: 'OUTCOME_SALES', value: 'OUTCOME_SALES'},
    {id: 'OUTCOME_TRAFFIC', value: 'OUTCOME_TRAFFIC'},
    {id: 'OUTCOME_APP_PROMOTION', value: 'OUTCOME_APP_PROMOTION'},
    {id: 'CONVERSIONS', value: 'CONVERSIONS'}
]


const CreateOrEditCampaignModal = ({adAccountUID, onCreate, onCancel, campaignValues}) => {
    const [isCampaignChanging, setIsCampaignChanging] = useState(false)
    const campaignUID = campaignValues?.uid

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is Required!"),
        objective: Yup.string().required("Objective is Required!"),
        status: Yup.string().required("Status is Required!"),
        specialAdCategories: Yup.array()
    });
    const initialValues = {
        name: campaignValues?.name || "",
        objective: campaignValues?.objective || "",
        status: campaignValues?.status || "",
        specialAdCategories: campaignValues?.specialAdCategories || []
    };

    const handleCreateOrEditCampaign = values => {
        setIsCampaignChanging(true)

        let formData = new FormData();
        formData.append('name', values.name);
        formData.append('objective', values.objective);
        formData.append('status', values.status);
        formData.append('special_ad_categories', values.specialAdCategories);
        formData.append('ad_account', adAccountUID);

        const backendAPI = new BackendService()
        if (campaignUID) {
            backendAPI.editMetaCampaign(formData, campaignValues.uid)
                .then(response => {
                    console.log(response)
                    setIsCampaignChanging(false)
                    onCreate()
                })
                .catch(error => setIsCampaignChanging(false))
        } else {
            backendAPI.createMetaCampaign(formData)
                .then(response => {
                    setIsCampaignChanging(false)
                    onCreate()
                })
                .catch(error => setIsCampaignChanging(false))
        }

    }

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => handleCreateOrEditCampaign(values)
    });

    return <Box pt={2} pb={4}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card sx={{
                        p: 3
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FlexBox gap={0.5} alignItems="center">
                                    <IconWrapper>
                                        {campaignUID ?
                                            <Edit sx={{color: "primary.main"}}/> :
                                            <Add sx={{color: "primary.main"}}/>}

                                    </IconWrapper>

                                    <H6 fontSize={16}>{campaignUID ? `Edit campaign - "${values.name}"` : 'Create New Campaign'}</H6>
                                </FlexBox>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label={'Campaign title'}
                                                   name={"name"}
                                                   fullWidth
                                                   onChange={handleChange}
                                                   value={values.name}
                                                   helperText={touched.name && errors.name}
                                                   error={Boolean(touched.name && errors.name)}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <TextField select
                                                   fullWidth
                                                   name={'objective'}
                                                   value={values.objective}
                                                   onChange={handleChange}
                                                   SelectProps={{
                                                       native: true,
                                                       IconComponent: KeyboardArrowDown
                                                   }}>
                                            <option/>
                                            {CAMPAIGN_OBJECTIVE_OPTIONS.map((item) =>
                                                (<option key={item.id} value={item.id}>{item.value}</option>))}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <TextField select
                                                   fullWidth
                                                   name={'status'}
                                                   value={values.status}
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   SelectProps={{
                                                       native: true,
                                                       IconComponent: KeyboardArrowDown
                                                   }}>
                                            <option/>
                                            {CAMPAIGN_STATUS_OPTIONS.map((item) =>
                                                (<option key={item.id} value={item.id}>{item.value}</option>))}
                                        </TextField>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <TextField fullWidth name="specialAdCategories" label="Special ad categories"
                                                   onBlur={handleBlur}
                                                   onChange={handleChange}
                                                   disabled={true}
                                                   value={values.specialAdCategories}
                                                   helperText={touched.specialAdCategories && errors.specialAdCategories}
                                                   error={Boolean(touched.specialAdCategories && errors.specialAdCategories)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <FlexBox flexWrap="wrap" gap={2}>
                        <LoadingButton loading={isCampaignChanging} type="submit" variant="contained">
                            {campaignUID ? 'Edit campaign' : 'Create New Campaign'}
                        </LoadingButton>

                        <Button variant="outlined" color="secondary" onClick={onCancel}>
                            Cancel
                        </Button>
                    </FlexBox>
                </Grid>
            </Grid>
        </form>
    </Box>;
};

export default CreateOrEditCampaignModal;