import {useState} from "react";
import {Avatar, Box, Checkbox, Chip, TableCell, TableRow} from "@mui/material";
import {DeleteOutline, Edit, RemoveRedEye} from "@mui/icons-material";
import {format} from "date-fns";
import {FlexBox} from "../../../../components/flexbox";
import {Paragraph} from "../../../../components/typography";
import {TableMoreMenu, TableMoreMenuItem} from "../../../../components/table";
import useNavigate from "../../../../hooks/useNavigate";
import CreateOrEditCampaignModal from "./CreateOrEditCampaignModal";
import Modal from "../../../../components/modal/Modal";


const CampaignTableRow = ({
                              campaign,
                              isSelected,
                              handleSelectRow,
                              handleDeleteProduct,
                              handleReloadPage,
                              adAccountUID
                          }) => {
    const navigate = useNavigate();
    const [openMenuEl, setOpenMenuEl] = useState(null);
    const [isCampaignEditModalOpen, setIsCampaignEditModalOpen] = useState(false)

    const handleOpenMenu = event => {
        setOpenMenuEl(event.currentTarget);
    };

    const handleToggleCampaignEditModal = () => {
        setIsCampaignEditModalOpen(prevState => !prevState)
    }

    const handleCloseOpenMenu = () => setOpenMenuEl(null);

    return <TableRow hover>
        {isCampaignEditModalOpen &&
            <Modal handleClose={handleToggleCampaignEditModal}
                   open={isCampaignEditModalOpen}
                   modalProps={{slotProps: {backdrop: {sx: {backgroundColor: '#6950E8', filter: 'blur(300px)'}}}}}
            >
                <CreateOrEditCampaignModal
                    onCreate={handleReloadPage}
                    onCancel={handleToggleCampaignEditModal}
                    adAccountUID={adAccountUID}
                    campaignValues={campaign}
                />
            </Modal>}
        <TableCell padding="checkbox">
            <Checkbox size="small" color="primary" checked={isSelected}
                      onClick={event => handleSelectRow(event, campaign.uid)}/>
        </TableCell>

        <TableCell padding="normal">
            <FlexBox alignItems="center" gap={2}>
                <Avatar variant="rounded" alt={campaign.name} src={campaign.image} sx={{
                    width: 50,
                    height: 50
                }}/>

                <Box>
                    <Paragraph fontWeight={500} color="text.primary" sx={{
                        ":hover": {
                            textDecoration: "underline",
                            cursor: "pointer"
                        }
                    }}>
                        {campaign.name}
                    </Paragraph>
                    <Paragraph fontSize={13}>{campaign.category}</Paragraph>
                </Box>
            </FlexBox>
        </TableCell>

        <TableCell padding="normal">{format(new Date(campaign.created_at), "dd MMM yyyy")}</TableCell>

        <TableCell padding="normal" sx={{...(campaign.objective === 0 && {color: "error.main"})}}>
            {campaign.objective}
        </TableCell>

        <TableCell padding="normal">{campaign.special_ad_categories}</TableCell>

        <TableCell padding="normal">
            <Chip label={campaign.status} color={campaign.status === 'ACTIVE' ? 'success' : 'error'}/>
        </TableCell>

        <TableCell padding="normal" align="right">
            <TableMoreMenu open={openMenuEl} handleOpen={handleOpenMenu} handleClose={handleCloseOpenMenu}>
                <TableMoreMenuItem Icon={RemoveRedEye} title="View" handleClick={() => {
                    handleCloseOpenMenu();
                    navigate("/ads/meta");
                }}/>
                <TableMoreMenuItem Icon={Edit} title="Edit" handleClick={handleToggleCampaignEditModal}/>
                <TableMoreMenuItem Icon={DeleteOutline} title="Delete" handleClick={() => {
                    handleCloseOpenMenu();
                    handleDeleteProduct(campaign.uid);
                }}/>
            </TableMoreMenu>
        </TableCell>
    </TableRow>;
};

export default CampaignTableRow;