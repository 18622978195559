import DashboardHeader from "layouts/dashboard/DashboardHeader";
import DashboardSidebar from "layouts/dashboard/DashboardSidebar";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import LayoutProvider from "layouts/dashboard/context/layoutContext";
import CampaignListView from "./page-view/CampaignListView";

const CampaignListViewPage = () => {
    return <LayoutProvider>
        <DashboardSidebar/>
        <LayoutBodyWrapper>
            <DashboardHeader/>
            <CampaignListView/>
        </LayoutBodyWrapper>
    </LayoutProvider>;
};

export default CampaignListViewPage;