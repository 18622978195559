import DashboardHeader from "layouts/dashboard/DashboardHeader";
import DashboardSidebar from "layouts/dashboard/DashboardSidebar";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import LayoutProvider from "layouts/dashboard/context/layoutContext";
import DashboardPageSections from "./PageSections";

const DashboardPage = ({children}) => {
    return <LayoutProvider>
        <DashboardSidebar/>

        <LayoutBodyWrapper>
            <DashboardHeader/>
            <DashboardPageSections/>
        </LayoutBodyWrapper>
    </LayoutProvider>;
};

export default DashboardPage;