class Campaign {
    constructor(data) {
        this.uid = data.uid
        this.campaign_id = data.campaign_id
        this.name = data.name
        this.status = data.status
        this.created_at = data.created_at
        this.objective = data.objective
        this.special_ad_categories = data.special_ad_categories
        this.image = ''
    }
}

export default Campaign