import axios from 'axios'
import {toast} from 'material-react-toastify'
import Toast from "../components/toast/Toast";

const backendHost = process.env.REACT_APP_BACKEND_HOST
export const backendAdminURL = `${backendHost}/admin`


const setAuthorization = () => {
    const {access} = getAuthToken();
    if (access) {
        api.defaults.headers.common['Authorization'] = `Bearer ${access}`
    }
}


export const saveAuthToken = token => {
    localStorage.setItem("userDetails", JSON.stringify(token))
}

export const getAuthToken = () => {
    const tokenString = localStorage.getItem("userDetails")
    if (tokenString !== undefined) {
        const userToken = JSON.parse(tokenString)
        return {access: userToken?.access, refresh: userToken?.refresh}
    } else {
        return {access: null, refresh: null}
    }
}


export const deleteAuthToken = () => {
    localStorage.removeItem("userDetails")
}


const handleAPIError = error => {
    const requestID = error.response?.headers?.request_id
    const errorMessage = error.response?.statusText || error.response?.data[0] || 'Server error'
    toast.error(<Toast title={requestID} text={errorMessage}/>, {toastId: 'APIError'})

    if (error.response?.status === 401) {
        deleteAuthToken()
        window.location.href = '/login'
    }
}
let api = axios.create({
    baseURL: backendHost,
    timeout: 10000,
    headers: {'Content-Type': 'application/json'}
});

api.interceptors.response.use(response => response, error => {
    handleAPIError(error)
    throw error
})


export default class BackendService {
    constructor() {
        this.host = process.env.REACT_APP_BACKEND_HOST;
        setAuthorization()
    };

    async obtainAuthToken(email, password, remember) {
        deleteAuthToken()
        const requestPath = 'accounts/login/'
        let data = new FormData();
        data.append('email', email);
        data.append('password', password);
        data.append('remember', remember);

        return api.post(requestPath, data).then(
            response => response.data
        )
    }

    async refreshAuthToken() {
        const requestPath = "api/v1/token/refresh/"

        const {access, refresh} = getAuthToken();
        const requestOptions = {access: access, refresh: refresh}
        return api.post(requestPath, requestOptions)
            .then(response => {
                    saveAuthToken(response.data())
                }
            )
    }

    async getMetaRequestPermissionsPageURL() {
        const requestPath = '/fb-ads/fb-ads-permission-request'

        return api.get(requestPath)
            .then(response => response.data)
    }

    async saveMetaRequestPermissionsToken(token) {
        const requestPath = `/fb-ads/fb-ads-permission-acquire?code=${token}`

        return api.get(requestPath)
            .then(response => response.data)
    }

    async getMetaAdAccounts() {
        const requestPath = '/fb-ads/ad-accounts'
        return api.get(requestPath).then(response => response.data)
    }

    async syncMetaAds() {
        const requestPath = '/fb-ads/fb-ad-account-data-sync'
        return api.get(requestPath).then(response => response.data)
    }

    async getMetaListOfCampaigns(adAccountUID, filters, offset = 0, limit = 10) {
        const requestPath = '/fb-ads/campaigns'
        let requestParams = {limit: limit, offset: offset}
        if (adAccountUID) {
            requestParams['ad_account_uid'] = adAccountUID
        }

        if (filters) {
            requestParams = {...requestParams, ...filters}
        }

        return api.get(requestPath, {params: requestParams}).then(response => response.data)
    }

    async createMetaCampaign(formData) {
        const requestPath = '/fb-ads/campaigns/'
        return api.post(requestPath, formData).then(
            response => response.data
        )
    }

    async editMetaCampaign(formData, campaignUID) {
        const requestPath = `/fb-ads/campaigns/${campaignUID}/`
        console.log(formData)
        return api.put(requestPath, formData).then(response => response.data)
    }

    async deleteMetaCampaign(campaignUID) {
        const requestPath = `/fb-ads/campaigns/${campaignUID}/`
        return api.delete(requestPath).then(response => response.data)
    }

}