import {useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    MenuItem,
    styled,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    TextField
} from "@mui/material";
import Add from "../../../icons/Add";
import useNavigate from "../../../hooks/useNavigate";
import {Scrollbar} from "../../../components/scrollbar";
import {FlexBetween} from "../../../components/flexbox";

import {TableDataNotFound, TableToolbar} from "../../../components/table";
import useMuiTable from "../../../hooks/useMuiTable";
import CampaignFilter from "../components/campaign/CampaignFilter";
import {SelectFieldWrapper} from "../components/SelectFieldWrapper";
import CampaignsListHead from "../components/campaign/CampaignsListHead";
import CampaignTableRow from "../components/campaign/CampaignTableRow";
import BackendService from "../../../utils/backendService";
import Modal from "../../../components/modal/Modal";
import ConnectToMetaWarning from "../components/WarningToConnect";
import Restore from "../../../icons/Restore";
import {SplashScreen} from "../../../components/splash-screen";
import Facebook from "../../../icons/Facebook";
import CreateOrEditCampaignModal from "../components/campaign/CreateOrEditCampaignModal";
import Campaign from "../../../contexts/models/Campaign"


const ListWrapper = styled(FlexBetween)(({theme}) => ({
    gap: 16,
    [theme.breakpoints.down(440)]: {
        flexDirection: "column",
        ".MuiButton-root": {
            width: "100%"
        }
    }
}));

const AD_ACCOUNTS = [
    {uid: '2b6a1504-a510-47f9-8e45-48d1ef65e2b1', account_id: 1, name: 'AC1'}
]


const CAMPAIGNS = [
    new Campaign({
        special_ad_categories: [],
        objective: 'OUTCOME_TRAFFIC',
        uid: "2b6a1504-a510-47f9-8e45-48d1ef65e2b0",
        campaign_id: 1112,
        status: 'PAUSED',
        name: "My Campaign 1",
        created_at: "2023-06-08T18:00:00.000Z",
        image: "/static/products/type-writer.png"
    })]

const DEFAULT_ROWS_PER_PAGE = 5

const CampaignListView = () => {
    const navigate = useNavigate();
    const [adAccounts, setAdAccounts] = useState(AD_ACCOUNTS)
    const [currentAdAccount, setCurrentAdAccount] = useState(AD_ACCOUNTS[0].uid)
    const [campaigns, setCampaigns] = useState([...CAMPAIGNS]);

    const [numberOfCampaigns, setNumberOfCampaigns] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [showConnectToMetaWarning, setShowConnectToMetaWarning] = useState(false)
    const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false)
    const [campaignFilter, setCampaignFilter] = useState({
        All: 'ALL',
        Active: 'ACTIVE',
        Paused: 'PAUSED',
        status: 'ALL'
    });

    useEffect(() => {
        handleFetchAdAccountsData()
    }, []);


    useEffect(() => {
        handleFetchAdCampaignsData(0, rowsPerPage)
    }, [currentAdAccount]);

    // useEffect(() => {
    //     filterCampaigns()
    // }, [campaigns]);

    useEffect(() => {
        const filters = {}
        if (campaignFilter.search) {
            filters['q'] = campaignFilter.search
        } else if (['ACTIVE', 'PAUSED'].includes(campaignFilter.status)) {
            filters['status'] = campaignFilter.status
        }
        handleFetchAdCampaignsData(0, rowsPerPage, filters)
    }, [campaignFilter])

    const handleFetchAdAccountsData = () => {
        const backendAPI = new BackendService()
        setIsLoading(true)
        backendAPI.getMetaAdAccounts()
            .then(response => {
                if (response.results.length > 0) {
                    setAdAccounts(response.results)
                    setCurrentAdAccount(response.results[0].uid)
                } else {
                    setShowConnectToMetaWarning(true)
                }
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

    const handleFetchAdCampaignsData = (page, numItemsPerPage, filters) => {
        const offset = numItemsPerPage * page
        const backendAPI = new BackendService()
        backendAPI.getMetaListOfCampaigns(currentAdAccount, filters, offset, numItemsPerPage)
            .then(response => {
                const data = response.results;
                const campaignsList = data.map((campaign => {
                    const campaignData = {
                        uid: campaign.uid,
                        campaign_id: campaign.campaign_id,
                        name: campaign.name,
                        objective: campaign.objective,
                        status: campaign.status,
                        special_ad_categories: campaign.special_ad_categories,
                        created_at: campaign.created_time,
                        image: ''
                    }

                    return new Campaign(campaignData)
                }))
                setCampaigns(_ => campaignsList)
                setNumberOfCampaigns(response.count)
            })
    }

    const handleChangeFilter = (key, value) => {
        setCampaignFilter(state => ({
            ...state,
            [key]: value
        }));
    };

    const {
        page,
        order,
        orderBy,
        selected,
        isSelected,
        rowsPerPage,
        handleSelectRow,
        handleChangePage,
        handleRequestSort,
        handleSelectAllRows,
        handleChangeRowsPerPage
    } = useMuiTable({
        defaultOrderBy: "name",
        defaultRowsPerPage: DEFAULT_ROWS_PER_PAGE
    });

    const handleReloadPage = () => {
        window.location.reload();
    }

    const handleDeleteProduct = id => {
        const backendAPI = new BackendService()
        backendAPI.deleteMetaCampaign(id).then(
            response => handleReloadPage()
        )
    };

    const handleAllProductDelete = () => {
        const backendAPI = new BackendService()
        for (const uid of selected) {
            backendAPI.deleteMetaCampaign(uid).then(
                response => handleReloadPage()
            )
        }
    };

    const handleCloseShowWarningToConnect = () => {
        setShowConnectToMetaWarning(false)
    }

    const handleToggleCreateCampaignModal = () => {
        setShowCreateCampaignModal(prev => !prev)
    }
    const handleSyncAds = () => {
        setIsLoading(true)
        const backendAPI = new BackendService()
        backendAPI.syncMetaAds().then(response => {
                setIsLoading(false)
                handleFetchAdAccountsData()
                handleReloadPage()
            }
        ).catch(error => setIsLoading(false))
    }
    const handlePageChangeFromAPI = (event, page) => {
        handleFetchAdCampaignsData(page, rowsPerPage)
        handleChangePage(event, page)
    }

    const handleChangeRowsPerPageFromAPI = (event) => {
        const rowsPerPage = event.target.value
        handleFetchAdCampaignsData(0, rowsPerPage)
        handleChangeRowsPerPage(event)
    }

    let connectToMetaWarningModal;
    if (showConnectToMetaWarning) {
        connectToMetaWarningModal = <Modal handleClose={() => null}
                                           open={showConnectToMetaWarning}
                                           modalProps={{
                                               slotProps: {
                                                   backdrop: {
                                                       sx: {
                                                           backgroundColor: '#6950E8',
                                                           filter: 'blur(300px)'
                                                       }
                                                   }
                                               }
                                           }}
        >
            <ConnectToMetaWarning handleCancel={handleCloseShowWarningToConnect}/>
        </Modal>
    }

    if (isLoading) {
        return <SplashScreen/>
    }
    return <Box pt={2} pb={4}>
        {showCreateCampaignModal &&
            <Modal handleClose={handleToggleCreateCampaignModal}
                   open={showCreateCampaignModal}
                   modalProps={{slotProps: {backdrop: {sx: {backgroundColor: '#6950E8', filter: 'blur(300px)'}}}}}
            >
                <CreateOrEditCampaignModal
                    onCreate={handleReloadPage}
                    onCancel={handleToggleCreateCampaignModal}
                    adAccountUID={currentAdAccount}
                />
            </Modal>
        }

        {connectToMetaWarningModal}
        <ListWrapper>
            <SelectFieldWrapper gap={2} px={2} py={4}>
                <TextField select fullWidth label="Ad accounts" className="select" value={currentAdAccount}
                           onChange={e => setCurrentAdAccount(e.target.value)}>
                    {adAccounts.map(({uid, name}) => <MenuItem key={uid} value={uid}>
                        {name}
                    </MenuItem>)}
                </TextField>
            </SelectFieldWrapper>
            <ListWrapper>
                <Button variant="contained"
                        startIcon={<Add/>}
                        color={'success'}
                        onClick={handleToggleCreateCampaignModal}>
                    Create new campaign
                </Button>
                <Button variant="contained"
                        startIcon={<Restore/>}
                        color={'warning'}
                        onClick={handleSyncAds}>
                    Sync Ads
                </Button>
                <Button variant="contained"
                        startIcon={<Facebook/>}
                        onClick={() => navigate("/ads/meta/request_ads_permissions")}>
                    Connect to Meta
                </Button>
            </ListWrapper>

        </ListWrapper>

        <Card sx={{mt: 4}}>

            <CampaignFilter filter={campaignFilter} handleChangeFilter={handleChangeFilter}/>
            {selected.length > 0 &&
                <TableToolbar selected={selected.length === campaigns.length}
                              handleDeleteRows={handleAllProductDelete}/>}
            <TableContainer>
                <Scrollbar>
                    <Table sx={{minWidth: 820}}>
                        <CampaignsListHead order={order} orderBy={orderBy} numSelected={selected.length}
                                           rowCount={campaigns.length} onRequestSort={handleRequestSort}
                                           onSelectAllRows={handleSelectAllRows(campaigns.map(row => row.uid))}/>

                        <TableBody>
                            {campaigns.map(campaign =>
                                <CampaignTableRow key={campaign.uid} campaign={campaign}
                                                  handleSelectRow={handleSelectRow}
                                                  isSelected={isSelected(campaign.uid)}
                                                  handleDeleteProduct={handleDeleteProduct}
                                                  adAccountUID={currentAdAccount}
                                                  handleReloadPage={handleReloadPage}
                                />)
                            }

                            {campaigns.length === 0 && <TableDataNotFound/>}
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>

            <TablePagination page={page}
                             component="div"
                             rowsPerPage={rowsPerPage}
                             count={numberOfCampaigns}
                             onPageChange={handlePageChangeFromAPI}
                             rowsPerPageOptions={[5, 10, 25]}
                             onRowsPerPageChange={handleChangeRowsPerPageFromAPI}/>
        </Card>
    </Box>;
};

export default CampaignListView;