import {MenuItem, TextField} from "@mui/material";
import {SelectFieldWrapper} from "../SelectFieldWrapper";


const CampaignFilter = ({handleChangeFilter, filter}) => {
    const CAMPAIGN_STATUSES = [{
        id: 1,
        name: "All",
        value: "ALL"
    }, {
        id: 2,
        name: "Active",
        value: "ACTIVE"
    }, {
        id: 3,
        name: "Paused",
        value: "PAUSED"
    }];

    return <SelectFieldWrapper gap={2} px={2} py={4}>
        <TextField select
                   fullWidth
                   label="Status"
                   className="select"
                   value={filter.status}
                   onChange={e => handleChangeFilter("status", e.target.value)}>
            {CAMPAIGN_STATUSES.map(({id, name, value}) => <MenuItem key={id} value={value}>
                {name}
            </MenuItem>)}
        </TextField>

        <TextField fullWidth label="Search by campaign name..." value={filter.search}
                   onChange={e => handleChangeFilter("search", e.target.value)}/>

    </SelectFieldWrapper>;
};

export default CampaignFilter;