import {styled} from "@mui/material";
import {FlexBox} from "../../../components/flexbox";

export const SelectFieldWrapper = styled(FlexBox)(({theme}) => ({
    alignItems: "center",
    ".select": {
        flex: "1 1 200px"
    },
    [theme.breakpoints.down(440)]: {
        ".navigation": {
            display: "none"
        }
    }
}));