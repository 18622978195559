import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SettingsProvider from "./contexts/settingsContext";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SettingsProvider>
        <App/>
    </SettingsProvider>
);

reportWebVitals();
