import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes} from "./routes/routes";
import {AuthProvider} from "./contexts/authContext";
import "./i18n";
import {createCustomTheme} from "./theme";
import useSettings from "hooks/useSettings";
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {ToastContainer} from "material-react-toastify";
import 'material-react-toastify/dist/ReactToastify.css';

function App() {
    const router = createBrowserRouter(routes());
    const {settings} = useSettings();
    const theme = createCustomTheme(settings);

    return (
        <>
            <StyledEngineProvider injectFirst>
                <ToastContainer
                    position="top-center"
                    theme="light"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{width: '25%'}}
                />
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <CssBaseline/>
                        <RouterProvider router={router}/>
                    </AuthProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
}

export default App;

