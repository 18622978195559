import {useEffect, useState} from "react";
import BackendService from "../../utils/backendService";
import {SplashScreen} from "../../components/splash-screen";


const RedirectToFBRequestPermissionsPage = () => {
    const [redirectURL, setRedirectURL] = useState('')
    useEffect(() => {
        const backendAPI = new BackendService()
        backendAPI.getMetaRequestPermissionsPageURL()
            .then(data => {
                    setRedirectURL(data.url)
                }
            )
    }, []);

    if (redirectURL) {
        window.location.assign(redirectURL);
    }
    return <SplashScreen/>
};

export default RedirectToFBRequestPermissionsPage