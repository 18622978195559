import duotone from "icons/duotone";

export const navigations = [{
    type: "label",
    label: "Dashboard"
}, {
    name: "Home",
    path: "/",
    icon: duotone.PersonChalkboard
}, {
    name: "Ads",
    path: "#",
    icon: duotone.LayerGroup,
    children: [
        {
            name: "Meta",
            path: "/ads/meta"
        }, {
            name: "Google",
            path: "#",
            disabled: true
        }, {
            name: "Linkedin",
            path: "#",
            disabled: true
        }
    ]
}, {
    type: "extLink",
    name: "Documentation",
    icon: duotone.FileCircleQuestion,
    path: "https://quickframe-doc.vercel.app/"
}];