import {useSearchParams} from "react-router-dom";
import useNavigate from "../../hooks/useNavigate";
import {useEffect, useState} from "react";
import BackendService from "../../utils/backendService";
import {SplashScreen} from "../../components/splash-screen";

const AcquireFBPermissionsAccessToken = () => {
    const [searchParams, _] = useSearchParams();
    const navigate = useNavigate();
    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(() => {
        if (searchParams.get('code')) {
            const backendApi = new BackendService()
            backendApi.saveMetaRequestPermissionsToken(searchParams.get('code'))
                .then(response => {
                    setIsAuthorized(true)
                })
        }

    }, [searchParams]);
    if (isAuthorized) {
        return navigate('/ads/meta')
    }
    return <SplashScreen/>
}

export default AcquireFBPermissionsAccessToken