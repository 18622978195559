import {Box, Card, useTheme} from "@mui/material";
import Chart from "react-apexcharts";
import {Title} from "components/title";
import merge from "lodash.merge";

import {baseChartOptions} from "utils/baseChartOptions";

const TotalItems = () => {
    const theme = useTheme();

    const chartCategories = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"]; // REACT CHART DATA SERIES

    const chartSeries = [{
        name: "Tasks",
        data: [22, 30, 46, 50, 46, 30, 22]
    }];

    const chartOptions = merge(baseChartOptions(theme), {
        stroke: {
            show: false
        },
        xaxis: {
            categories: chartCategories
        },
        colors: [theme.palette.divider, theme.palette.primary.main],
        plotOptions: {
            bar: {
                borderRadius: 5,
                distributed: true,
                columnWidth: "40%",
                borderRadiusApplication: "end"
            }
        },
        tooltip: {
            y: {
                formatter: (val, {
                    dataPointIndex,
                    w
                }) => {
                    return `${w.globals.labels[dataPointIndex]} : ${val}`;
                }
            }
        }
    });
    return <Card>
        <Box p={3} pb={0}>
            <Title title={1352} subtitle="Empressions" percentageType="primary" percentage="+12.5%"/>
        </Box>

        <Chart type="bar" series={chartSeries} options={chartOptions} height={130}/>
    </Card>;
};

export default TotalItems;