import {Box, Button, Grid} from "@mui/material";

import {H6, Paragraph} from "../../../components/typography";
import {FlexBox} from "../../../components/flexbox";
import useNavigate from "../../../hooks/useNavigate";

const ConnectToMetaWarning = ({handleCancel}) => {
    const navigate = useNavigate();

    return <form>
        <H6 fontSize={16}>Connect to Meta account</H6>

        <Box py={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paragraph>In order to use our functionality please connect to your Meta Ads account</Paragraph>
                </Grid>
            </Grid>
        </Box>

        <FlexBox alignItems="center" justifyContent="end" gap={1} mt={1}>
            <Button variant="outlined"
                    color="secondary"
                    onClick={() => navigate("/")}
            >
                Cancel
            </Button>
            <Button variant="contained"
                    onClick={() => navigate("/ads/meta/request_ads_permissions")}
            >Connect to Meta Account</Button>
        </FlexBox>
    </form>;
};

export default ConnectToMetaWarning;