import DashboardPage from "../pages/dashboard/DashboardPage";
import {ForgetPasswordPageView, LoginPageView, Logout, RegisterPageView} from "../pages/login";
import {AuthGuard} from "../components/auth";
import ErrorPage from "../pages/other/ErrorPage";
import ProductDetailsPageView from "../pages/meta/page-view/details";
import CampaignListViewPage from "../pages/meta/CampaignListViewPage";
import RedirectToFBRequestPermissionsPage from "../pages/meta/RedirectToFBRequestPermissionsPage";
import AcquireFBPermissionsAccessToken from "../pages/meta/AcquireFBPermissionsAccessToken";

export const routes = () => {
    return [{
        path: "/login", element: <LoginPageView/>
    }, {
        path: "/logout", element: <AuthGuard><Logout/></AuthGuard>
    }, {
        path: "/", element: <AuthGuard><DashboardPage/></AuthGuard>
    }, {
        path: "/register", element: <RegisterPageView/>
    }, {
        path: "/forget-password", element: <ForgetPasswordPageView/>
    }, {
        path: "/ads/meta", element: <AuthGuard><CampaignListViewPage/></AuthGuard>
    }, {
        path: "ads/meta/request_ads_permissions", element: <AuthGuard><RedirectToFBRequestPermissionsPage/></AuthGuard>
    }, {
        path: "ads/meta/acquire_ads_permissions", element: <AuthGuard><AcquireFBPermissionsAccessToken/></AuthGuard>
    }, {
        path: "ads/meta/details", element: <ProductDetailsPageView/>
    }, {
        path: "*", element: <ErrorPage/>
    }]

};