const Toast = ({title, text}) => {
    return (
        <>
            <div>
                <h6>{title}</h6>
                <p>{text}</p>
            </div>
        </>
    )
}

export default Toast